/**
 * @function { iconSvg|icon}
 * @returns { string } - html string
 * */
const iconSvg = name => `<svg class="c-icon c-icon-svg c-icon-svg-${name}">
            <use xlink:href="/assets/images/icons.svg#${name}">
              </use></svg>`;
const icon = name => `<span class="c-icon c-icon-${name}">&nbsp;</span>`;

export { iconSvg, icon };
