import goals from './components/goals';
import App from './app';

/*
Category: 'Clicks'
Action: 'Click on Phone' - Клик на телефон
Action: 'Click on Email' - Клик на почту
Action: 'Click on WhatsApp' - Клик на WhatsApp
Action: 'Click on Telegram' - Клик на Telegram

Category: Email
Action: 'Email Feedback' - ФОС на странице контакты или в подвале где   контактные данные
Action: 'Email Callback' - Заказ звонка
Action: 'Email Review' - Добавление отзыва
Action: 'Email Specialist' - Вызов специалиста
Action: 'Email Enquiry' - это когда заполняют Book Now форму

Только по требованию:
Category: 'Ecommerce'
Action: 'View Product' - Просмотр продукта
Action: 'Add to Cart' - Добавление в корзину
Action: 'Remove From Cart' - Удаление из корзины
Action: 'Purchase' - Покупка
Action: 'Get Price List' - Получить прайс
Action: 'Enquire' - Отправить запрос
Action: 'Quick Order' - Заказ без корзины
Action: 'Review Product' - Отзыв к товару
*/

(($) => {
  $(document)
    .on('click', '[href^="tel:"]', () => {
      goals.send('Click on Phone', 'Clicks');
    })
    .on('click', '[href^="mailto:"]', () => {
      goals.send('Click on Email', 'Clicks');
    })
    .on('click', '[href*="api.whatsapp.com"],'
      + '[href^="whatsapp:"],[href*="wa.me"]', () => {
      goals.send('Click on Social', 'Clicks');
    })
    .on('click', '[href*="facebook.com"]', () => {
      goals.send('Click on Social', 'Clicks');
    })
    .on('click', '[href*="instagram.com"]', () => {
      goals.send('Click on Social', 'Clicks');
    })
    .on('click', '[href*="telegram.me"], [href^="tg:"]', () => {
      goals.send('Click on Social', 'Clicks');
    })
    .on('click', '[href*=".pdf"]', () => {
      goals.send('Click on Download', 'Clicks');
    })

    .on(App.triggers.form.success, (event, data) => {
      if (data && (data.form || data.$form)) {
        const $form = $(data.form || data.$form);
        const action = $form.data('goal-name') || false;
        const category = $form.data('goal-category') || 'Email';
        if (action) {
          goals.send(action, category);
          if(category !== 'Ecommerce') goals.send('Marketing', 'Marketing');
        }
      }
    });
})(jQuery);

export default goals;
