import App from '../app';

/**
 * Коллбеки принимают data, обрабатывают ее и должны вернуть
 * @param {object} event - событие формы
 * @param {object} options - опции ajax запроса
 * @param {function} onSuccess(data) - коллбек успеха
 * @param {function} onError(data) - коллбек ошибки
 */

export default function sendForm(event, onSuccess, onError, options) {
  event.preventDefault();
  const form = event.target;
  if (form.dataset.submitted) return true;
  const config = $.extend({
    url: form.action || '',
    type: form.method || 'POST',
    processData: false,
    contentType: false,
    data: new FormData(form),
  }, options || {});
  App.preloader.add();
  form.dataset.submitted = 'true';
  $(form)
    .find('[type="submit"], [type="reset"]')
    .attr('disabled', 'disabled')
    .addClass('is-disabled is-loading');
  return $.ajax(config)
    .done((response) => {
      let data;
      if (typeof response !== 'object') {
        data = {
          form,
          html: response.toString(),
        };
      } else {
        data = $.extend(response, { form });
      }
      if (DEV_ENV && window.location.hostname === 'localhost') {
        data = {
          form,
          html: response,
          success: true,
          cartTotals: '10',
          cartWidget: '<div id="js-cart-widget">AJAX</div>',
          favoritesWidget: '<div id="js-favorites-widget">AJAX</div>',
          message: 'Успех',
        };
      }
      if (data.redirectUrl && App.redirect) {
        return App.redirect(data.redirectUrl);
      }

      if (data.success && typeof onSuccess === 'function') {
        data = $.extend(data, onSuccess(data) || {});
      } else if (typeof onError === 'function') {
        data = $.extend(data, onError(data) || {});
      }
      if (data.message && App.notify) App.notify(data.message, data.success ? 'success' : 'error');
      $(document).trigger(App.triggers.ajax, data);
      $(document)
        .trigger((data.success ? App.triggers.form.success : App.triggers.form.error), data);
      return data;
    })
    .always(() => {
      form.removeAttribute('data-submitted');
      $(form)
        .find('[type="submit"], [type="reset"]')
        .removeAttr('disabled')
        .removeClass('is-disabled is-loading');
      App.preloader.remove();
    })
    .fail(App.onAjaxFail);
}
