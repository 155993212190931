class Popup {
  constructor(html, options) {
    this.popup = null;
    this.isActive = false;
    this.options = Object.assign({
      classname: '',
      transition: 200,
    }, options);
    const transitionTime = `${this.options.transition / 1000}s`;
    this.html = `<div class="c-popup ${this.options.classname}" style="animation-duration: ${transitionTime}">
      <div class="c-popup__container">
        <div class="c-popup__inner">
          <div class="c-popup__body" style="animation-duration: ${transitionTime}">
            <div class="c-popup__close" data-action="popup.close">
              <svg class="c-icon c-icon-svg c-icon-svg-close">
                  <use xlink:href="/assets/images/icons.svg#close"></use>
              </svg>
            </div>
            <div class="c-popup__content">${html}</div>
          </div>
        </div>
      </div></div>`;
    const el = document.createElement('Popup');
    el.innerHTML = this.html.trim();
    this.popup = el.firstChild;
    const buttonsClose = this.popup.querySelectorAll('[data-action="popup.close"]');
    this.popup.addEventListener('click', (event) => {
      if (!event.target.closest('.c-popup__body')) {
        this.close();
      }
    });
    if (buttonsClose) {
      [...buttonsClose].forEach((btn) => {
        btn.addEventListener('click', () => {
          this.close();
        });
      });
    }
  }

  open(callback) {
    if (!this.popup || this.isActive) {
      return false;
    }
    this.isActive = true;
    this.scrollTop = window.scrollY;
    document.body.setAttribute('style',
      `position:fixed;
            overflow:hidden;
            width:100%;
            height:100%;
            left:0px;
            top:0px;
            transform:translate(0,-${this.scrollTop}px);`);
    this.popup.setAttribute('style', `transform:translate(0,${this.scrollTop}px);`);
    document.body.appendChild(this.popup);
    callback && typeof callback === 'function' && callback(this.popup);
  }

  close(callback) {
    this.isActive = false;
    this.popup.parentNode.removeChild(this.popup);
    document.documentElement.setAttribute('style', '');
    document.body.setAttribute('style', '');
    window.scroll(0, this.scrollTop);
    callback && typeof callback === 'function' && callback(this.popup);
  }
}

export default Popup;
