import App from '../app';

(($) => {
  const form = $('.js-header-search');
  const input = form.find('[type="text"],[type="search"]');
  const btn = form.find('[type="submit"]') || form.find('button');
  const openForm = (event) => {
    if (!form.hasClass('is-expanded')) {
      form.addClass('is-expanded');
      btn.children('span').text('Найти');
      input.focus();
      event.preventDefault();
    }
  };
  const closeForm = () => {
    form.removeClass('is-expanded');
    btn.children('span').text('Поиск');
    input.val('');
  };
  form.on('click', (event) => {
    event.stopPropagation();
  });
  App.breakpoints.onBreakpoint(
    'xxs xs sm md',
    () => {
      input.off('focus', openForm);
      btn.off('click', openForm);
      btn.children('span').text('Поиск');
      $(window).off('click', closeForm);
    },
    () => {
      closeForm();
      input.on('focus', openForm);
      btn.on('click', openForm);
      $(window).on('click', closeForm);
    },
  );
})(jQuery);
