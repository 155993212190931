import App from '../app';
import { each } from '../utils/';

/**
 * @description сортировка колонок в выпадашке
 * ul > li(data-column=номер)
 * */

const sortColumns = (ul) => {
  if (!ul) return false;
  const sortedColumns = [];
  const li = ul.children;
  const columns = [...li].filter(item => item.dataset.column);
  if (!columns.length) return false;
  columns.forEach((column) => {
    const position = column.dataset.column;
    if (position !== undefined) {
      if (sortedColumns[position]) {
        const children = column.firstElementChild;
        sortedColumns[position].appendChild(children);
        column.parentNode.removeChild(column);
      } else {
        sortedColumns[position] = column;
      }
    }
  });
  if (sortedColumns.length) {
    return sortedColumns.forEach(column => ul.appendChild(column));
  }
  return sortedColumns;
};
(() => {
  const menuMain = document.getElementById('js-menu-main');
  if (!menuMain) return false;
  const roots = menuMain.querySelectorAll('ul');
  return App.breakpoints.onBreakpoint(
    'xxs xs sm md',
    false,
    each(roots, sortColumns),
  );
})();
