/**
 * управление брейкпоинтами приложения через связывание js и css (см. breakpoints.scss)
 * */
const breakpoints = (function () {
  const breakpointsHandler = () => {
    const value = breakpoints.getValue();
    const event = new CustomEvent('breakpoints.change', { detail: { breakpoints: { value } } });
    if (value !== breakpoints.current) {
      breakpoints.current = value;
      document.dispatchEvent(event);
      if (DEV_ENV) console.log(value);
    }
  };
  window.addEventListener('resize', breakpointsHandler);
  document.addEventListener('DOMContentLoaded', breakpointsHandler);
  return {
    current: '',
    getValue() {
      return window.getComputedStyle(document.body, ':before').getPropertyValue('content').replace(/\"/g, '');
    },
    /**
     * проверка текущего брейкпоинта на соответсвие любому из string
     * @function { currentOf }
     * @param { string } string - строка с брейкпоинтами вида 'breakpoint1 breakpoint2 breakpointN'
     * @returns { (string|false) } текущий брейкпоинт или false если не совпало
     * */
    currentOf(string) {
      const arr = string.split(' ').filter(breakpoint => breakpoint === this.current);
      return arr.length ? this.current : false;
    },
    /**
     * fn() при каждом изменении брейкпоинта
     * @function { onChange }
     * @param { function } fn
     * @returns { (string) } результат fn() или текущий брейкпоинт
     * */
    onChange(fn) {
      if (typeof fn === 'function') {
        const handler = data => fn(data);
        document.addEventListener('breakpoints.change', handler);
      }
      return this.current;
    },
    /**
     * @function { onBreakpoint }
     * @param { string } string - строка с брейкпоинтами вида 'breakpoint1 breakpoint2 breakpointN'
     * @param { function|boolean } fn - на любом из string
     * @param { function|boolean } cb - если не любой из string
     * @returns { (function|string) } результат fn() cb() или текущий брейкпоинт
     * если cb() не функиця то fn() сработает однажды, и наоборот
     * */
    onBreakpoint(string, fn, cb) {
      let allowFn = true;
      let allowCb = true;
      const handler = (data) => {
        if (this.currentOf(string)) {
          if (fn && allowFn && typeof fn === 'function') {
            allowFn = false;
            allowCb = true;
            return fn(data);
          }
        } else if (cb && allowCb && typeof cb === 'function') {
          allowCb = false;
          allowFn = true;
          return cb(data);
        }
        return this.current;
      };
      document.addEventListener('breakpoints.change', handler);
    },
    /**
     * @function { onEach }
     * @param { string } string - строка с брейкпоинтами вида 'breakpoint1 breakpoint2 breakpointN'
     * @param { function|boolean } fn - на каждом из string
     * @param { function|boolean } cb - один раз если не любой из string
     * @returns { (function|string) } результат fn() cb() или текущий брейкпоинт
     * */
    onEach(string, fn, cb) {
      let last = '';
      let allowCb = true;
      const handler = (data) => {
        if (this.current !== last) {
          last = this.current;
          if (this.currentOf(string)) {
            if (fn && typeof fn === 'function') {
              allowCb = true;
              return fn(data);
            }
          } else if (allowCb && cb && typeof cb === 'function') {
            allowCb = false;
            return cb(data);
          }
        }
        return this.current;
      };
      document.addEventListener('breakpoints.change', handler);
    },
  };
}());

export default breakpoints;
