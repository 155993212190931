import svg4everybody from 'svg4everybody';
import App from './app';
import lazyInit from './lazy';

import './forms';
import './scroll-top';
import goals from './seo-goals';
import './menu';
import './header';
import './popups';
import './gallery';

if (DEV_ENV) {
  window.App = App;
  window.$ = $;
}
window.Goals = goals;

App.stream.add(lazyInit);
document.addEventListener('DOMContentLoaded', () => {
  lazyInit(document);
  document.documentElement.classList.remove('no-js');
  if (App.userAgent.isIE) svg4everybody();
});
