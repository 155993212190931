import App from '../app';

(() => {
  /**
   * @description парсим нужные меню и создаем одно для мобил
   * @param { Object } menuRoots - руты меню
   * data-order - порядок рута
   * data-title - заголовок рута
   * data-link - ссылка рута
   * */
  let menuMobile = null;
  const toggles = document.querySelectorAll('.js-menu-toggle');
  const menuRoots = document.querySelectorAll('.js-menu-root');
  if (!menuRoots.length) return false;
  const dataRoots = [...menuRoots].map(node => ({
    ul: node.tagName === 'UL' ? node : node.querySelector('ul'),
    title: node.dataset.title || null,
    url: node.dataset.url || null,
    classname: node.dataset.class || null,
    order: node.dataset.order !== undefined ? node.dataset.order : null,
  }));
  dataRoots.sort((a, b) => {
    if (!a.order && !b.order) return 0;
    if (!a.order && b.order) return 1;
    if (a.order && !b.order) return -1;
    return a.order - b.order;
  });
  function parseItem(li) {
    const link = li.querySelector('.c-menu__item-link');
    const dropdown = li.querySelector('ul');
    const url = link.href || null;
    const classname = link.dataset.class;
    const active = link.closest('.c-menu__item').classList.contains('is-active');
    const title = link.textContent || '';
    let children = null;
    if (dropdown) {
      children = [...dropdown.children].map(item => parseItem(item));
    }
    return {
      title,
      classname,
      url,
      children,
      active,
    };
  }
  function makeRoot(root) {
    if (!root) {
      return [];
    }
    let data = [];
    if (root.title) {
      const item = {
        title: root.title,
        classname: root.classname,
        url: root.url,
      };
      if (root.ul) {
        item.children = [...root.ul.children].map(child => parseItem(child));
      }
      data.push(item);
    } else if (root.ul) {
      data = [...root.ul.children].map(child => parseItem(child));
    }
    return data;
  }
  function createData(roots) {
    return [].concat(...roots.map(root => makeRoot(root)));
  }
  if (toggles.length) {
    [...toggles].forEach((toggle) => {
      toggle.onclick = (event) => {
        event.preventDefault();
        if (menuMobile && menuMobile.active) {
          menuMobile.close();
        } else if (menuMobile) {
          menuMobile.open();
        }
      };
    });
  }
  App.breakpoints.onBreakpoint(
    'xxs xs sm md',
    () => {
      if (!menuMobile) {
        import(/* webpackChunkName: "menu-app" */'../classes/class-menu-app')
          .then((module) => {
            const MenuApp = module.default;
            menuMobile = new MenuApp(createData(dataRoots), {
              title: 'Меню',
              dropdownBtn: '<span>...</span>',
              onOpen: () => {
                if (toggles.length) {
                  [...toggles].forEach((toggle) => {
                    toggle.classList.add('is-active');
                  });
                }
                if (App.userAgent.isMobile) {
                  document.body.style.position = 'fixed';
                  document.body.style.width = '100%';
                  document.body.style.height = '100%';
                }
              },
              onClose: () => {
                if (toggles.length) {
                  [...toggles].forEach((toggle) => {
                    toggle.classList.remove('is-active');
                  });
                }
                document.body.style.position = '';
                document.body.style.width = '';
                document.body.style.height = '';
              },
            });
            document.body.appendChild(menuMobile.overlay);
            document.body.appendChild(menuMobile.menu);
            return MenuApp;
          })
          .catch(App.debug);
      }
    },
    () => {
      if (menuMobile && menuMobile.close) menuMobile.close();
    },
  );
  return menuMobile;
})();
