// TODO: переписать в расширяемый(input с изображениями) class
//  multiple
(function ($) {
  const methods = {
    init(options) {
      const settings = $.extend({
        buttonText: 'Browse',
        emptyText: 'Choose file',
        buttonClear: true,
        clearText: 'Remove',
        class: '',
        rtl: false,
        onChange(data) {},
        onReaderLoad(data) {},
      }, options);

      return this.each(function () {
        const input = $(this);
        let { buttonText } = settings;
        let { emptyText } = settings;
        let { clearText } = settings;
        let showClear = settings.buttonClear;
        let { rtl } = settings;
        let classes = settings.class;

        if (input.data('placeholder')) {
          emptyText = input.data('placeholder');
        }
        if (input.data('class')) {
          classes = input.data('class');
        }
        if (input.data('button')) {
          buttonText = input.data('button');
        }
        if (input.data('empty')) {
          emptyText = input.data('empty');
        }
        if (input.data('clearText')) {
          clearText = input.data('clearText');
        }

        if (input.data('clear') === false || input.data('clear') === 'false') {
          showClear = false;
        }
        if (input.data('rtl') === true || input.data('rtl') === 'true') {
          rtl = true;
        }

        if (rtl) {
          input.wrap(`<div class="c-input-file c-input-file--rtl ${classes}"></div>`);
        } else {
          input.wrap(`<div class="c-input-file ${classes}"></div>`);
        }

        const filename = $(`<div class="c-input-file__value"><span>${emptyText}</span></div>`);
        const btn = $(`<div class="c-input-file__btn" ><span>${buttonText}</span></div>`);
        const btnClear = $(`<div class="c-input-file__clear" title="${clearText}" style="display: none"><span>${clearText}</span></div>`);
        filename.insertAfter(input);
        btn.insertAfter(filename);


        if (showClear) {
          btnClear.insertAfter(filename);
        }
        input.on('change', (event) => {
          const filesSrc = [];
          const filesLength = input[0].files.length;

          const data = {
            input,
            files: input[0].files,
            src: filesSrc,
          };

          if (input[0].files && input[0].files[0]) {
            if (filesLength > 1) {
              let string = '';
              for (let i = 0; i < filesLength; i++) {
                string += `${input[0].files[i].name}, `;
              }
              filename.html(`<span>${string}</span>`);
            } else {
              filename.html(`<span>${input[0].files[0].name}</span>`);
            }


            for (let j = 0; j < filesLength; j++) {
              const reader = new FileReader();

              if (j === filesLength - 1) {
                reader.onload = function (event) {
                  filesSrc.push(event.target.result);
                  settings.onReaderLoad(data);
                };
              } else {
                reader.onload = function (event) {
                  filesSrc.push(event.target.result);
                };
              }
              reader.readAsDataURL(input[0].files[j]);
            }

            input.closest('.c-input-file').addClass('has-file');
            btn.hide();
            btnClear.show();
          } else {
            filename.html(`<span>${emptyText}</span>`);
            input.closest('.c-input-file').removeClass('has-file');
            btn.show();
            btnClear.hide();
          }

          settings.onChange(data);
        });

        btnClear.on('click', () => {
          input.val('').trigger('change');
        });

        btn.on('click', (event) => {
          input.click();
        });
      });
    },
  };

  $.fn.inputFile = function (method) {
    // логика вызова метода
    if (methods[method]) {
      return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
    } if (typeof method === 'object' || !method) {
      return methods.init.apply(this, arguments);
    }
    $.error(`Метод с именем ${method} не существует `);
  };
}($));
