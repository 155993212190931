(() => {
  const phones = document.querySelectorAll('.js-header-phone');
  if (phones && phones.length) {
    const toggleHandler = (event, self) => {
      const toggle = event.target;
      if (self.classList.contains('is-expanded')) {
        self.classList.remove('is-expanded');
        toggle.classList.remove('is-active');
      } else {
        self.classList.add('is-expanded');
        toggle.classList.add('is-active');
      }
      event.preventDefault();
    };
    phones.forEach((self) => {
      const toggle = self.querySelector('[data-toggle]');
      if (toggle) {
        toggle.addEventListener('click', event => toggleHandler(event, self));
        toggle.addEventListener('touchstart', event => toggleHandler(event, self));
      }
    });
  }
})();
