import sendForm from './ajax-send';
import formError from './form-error';

function loginFormHandler(data) {
  const { html } = data;
  if (html && $(html).find('.js-form-login').length) {
    formError(data);
  } else {
    document.location.reload(true);
  }
}

$(document)
  .on('submit', '.js-form-login', (event) => {
    sendForm(event, loginFormHandler, loginFormHandler);
  });
