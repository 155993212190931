import App from '../app';

(($) => {
  const toggleHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const widget = $(event.target).closest('#js-header-cart');
    widget.toggleClass('is-expanded');
  };
  $(document)
    .on('click', () => {
      $('#js-header-cart').removeClass('is-expanded');
    })
    .on('click', '#js-header-cart', (event) => {
      event.stopPropagation();
    })
    .on('click', '#js-header-cart [data-toggle]', toggleHandler);


  function initWidget() {
    const $cart = $('#js-header-cart');
    if ($cart.length) {
      import(/* webpackChunkName: "widget-cart" */'../modules/widget-cart')
        .then((module) => {
          const widget = module.default;
          widget($cart[0]);
          return widget;
        })
        .catch(App.debug);
    }
  }

  App.stream.add(() => {
    if (!App.breakpoints.currentOf('xxs xs sm')) initWidget();
  });

  App.breakpoints.onBreakpoint(
    'xxs xs sm',
    () => {
      const widget = $('#js-header-cart');
      $(document).off('click', '#js-header-cart [data-toggle]', toggleHandler);
      widget.removeClass('is-expanded');
    },
    initWidget,
  );
})(jQuery);
