import sendForm from './ajax-send';
import formError from './form-error';
import { closeable as formSuccess } from './form-success';
import './ecommerce';
import './user';
import './form-files';
import './forms-plugins';
import './recaptcha';

$(document)
  .on('submit', 'form.js-ajax-form', (event) => {
    sendForm(event, formSuccess, formError);
  });
