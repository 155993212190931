import App from './app';
import './owl-carousel';
import owlDestroy from './jquery-plugins/owl-destroy';
import './jquery-plugins/owl-two-rows';
import { lazyObserver } from './lazy';

(($) => {
  const sliderMain = document.getElementById('js-slider-main');
  function initSlider(slider) {
    if (slider && slider.children.length > 1) {
      slider.classList.add('owl-carousel');
      $(slider).owlCarousel({
        items: 1,
        nav: false,
        loop: true,
        dots: true,
        autoHeight: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        lazyLoad: true,
        // onInitialized: () => {
        //   const cloned = slider.querySelectorAll('.owl-item.cloned .js-lazy-img');
        //   [...cloned].forEach(node => lazyObserver.observe(node));
        // },
      });
    }
  }
  App.breakpoints.onBreakpoint(
    'xxs xs sm', false,
    () => {
      initSlider(sliderMain);
    },
  );
  return sliderMain;
})(jQuery);

(($) => {
  const sliderBanners = document.querySelector('.js-section-banners');
  function initSlider(slider) {
    if (slider && slider.children.length > 1) {
      slider.classList.add('owl-carousel');
      $(slider).owlCarousel({
        items: 1,
        nav: false,
        loop: false,
        dots: true,
        autoHeight: true,
        lazyLoad: true,
        margin: 30,
        responsive: {
          768: {
            items: 2,
          },
        },
      });
    }
  }
  App.breakpoints.onBreakpoint(
    'xxs xs sm md',
    () => {
      initSlider(sliderBanners);
    },
    () => {
      owlDestroy(sliderBanners);
    },
  );
  return sliderBanners;
})(jQuery);

(($) => {
  const sliderSteps = document.querySelector('.js-section-steps');
  function initSlider(slider) {
    if (slider && slider.children.length > 1) {
      slider.classList.add('owl-carousel');
      $(slider).owlCarousel({
        items: 1,
        nav: false,
        loop: false,
        dots: true,
        autoHeight: true,
        autoWidth: true,
        lazyLoad: true,
        margin: 30,
      });
    }
  }
  App.breakpoints.onBreakpoint(
    'xxs xs sm md',
    () => {
      initSlider(sliderSteps);
    },
    () => {
      owlDestroy(sliderSteps);
    },
  );
  return sliderSteps;
})(jQuery);

(($) => {
  const slidersArr = [...document.querySelectorAll('.js-products-slider')];
  function initSlider(slider) {
    if (slider.children.length > 1) {
      slider.classList.add('owl-carousel');
      $(slider).owlCarousel({
        items: 1,
        loop: false,
        nav: true,
        dots: false,
        margin: 0,
        autoHeight: true,
        responsive: {
          650: {
            items: 2,
          },
          1000: {
            items: 3,
            mouseDrag: false,
          },
          1200: {
            items: 4,
            mouseDrag: false,
          },
        },
      });
    }
  }
  if (slidersArr.length) slidersArr.forEach(slider => initSlider(slider));
  return slidersArr;
})(jQuery);

(() => {
  const self = document.querySelector('#js-section-products');
  if (!self) return false;
  const links = [...self.querySelectorAll('[data-tab]')];
  const activeLink = links.filter(link => link.matches('.is-active'));
  const tabs = [...self.querySelectorAll('[data-tab-id]')];
  function toggleTab(id) {
    links.forEach((link) => {
      const target = link;
      if (target.dataset.tab === id) {
        target.classList.add('is-active');
      } else {
        target.classList.remove('is-active');
      }
    });
    tabs.forEach((tab) => {
      const target = tab;
      if (target.dataset.tabId === id) {
        target.style.display = '';
      } else {
        target.style.display = 'none';
      }
    });
  }
  if (activeLink.length) toggleTab(activeLink[0].dataset.tab);
  self.addEventListener('click', (event) => {
    if (event.target.dataset && event.target.dataset.tab) {
      toggleTab(event.target.dataset.tab);
      event.preventDefault();
    }
  });
  return tabs;
})();

(($) => {
  const self = document.querySelector('.js-section-gallery');
  if (!self) return false;
  const selfHead = self.querySelector('.c-section__head');
  const slider = self.querySelector('[data-slider]');
  let nav = null;
  import(/* webpackChunkName: "" */'./jquery-plugins/photoswipe').then((module) => {
    const photoSwipe = module.default;
    photoSwipe.init(slider);
    return photoSwipe;
  }).catch(App.debug);
  function setScaled(owl) {
    if (!App.breakpoints.currentOf('xxs xs sm')) {
      $(owl).find('.scaled, .prev, .next').removeClass('scaled prev next');
      $(owl).find('.owl-item.active').eq(0).addClass('scaled')
        .prevAll()
        .addClass('prev');
      $(owl).find('.owl-item.active').eq(1).addClass('scaled')
        .nextAll()
        .addClass('next');
    }
  }
  function appendNav() {
    if (!nav) return false;
    if (App.breakpoints.currentOf('xxs xs sm')) {
      $(nav).appendTo(slider);
    } else {
      $(nav).appendTo(selfHead);
    }
    return nav;
  }
  function initSlider(owl) {
    if (slider && slider.children.length > 1) {
      owl.classList.add('owl-carousel');
      $(owl).owlCarousel({
        autoWidth: true,
        items: 2,
        loop: false,
        dots: false,
        nav: true,
        margin: 0,
        mouseDrag: false,
        owl2row: false,
        responsive: {
          768: {
            margin: 30,
            owl2row: true,
          },
        },
        onInitialized: () => {
          setScaled(owl);
          nav = $(owl).find('.owl-nav');
          App.breakpoints.onChange(() => {
            appendNav();
          });
        },
        onTranslated: () => {
          setScaled(owl);
        },
      });
    }
  }
  initSlider(slider);
  return slider;
})(jQuery);

(($) => {
  const self = document.querySelector('.js-section-promo');
  if (!self) return false;
  const selfHead = self.querySelector('.c-section__head');
  const slider = self.querySelector('[data-slider]');
  const items = self.querySelectorAll('.c-item-promo');

  function appendNav(nav) {
    if (!nav) return false;
    $(nav).appendTo(selfHead);
    return nav;
  }
  function setHeight() {
    const itemsArr = [...items];
    if (!itemsArr.length) return false;
    let height = 0;
    let itemBigIndex = null;
    itemsArr.forEach((item, index) => {
      item.style.minHeight = 'auto';
      if (height < item.offsetHeight) {
        height = item.offsetHeight;
        itemBigIndex = index;
      } else {
        item.style.minHeight = `${height}px`;
      }
    });
    let loopIndex = 0;
    while (loopIndex < itemBigIndex) {
      itemsArr[loopIndex].style.minHeight = `${height}px`;
      loopIndex += 1;
    }
    return items;
  }
  function initSlider(owl) {
    if (slider && slider.children.length > 1) {
      owl.classList.add('owl-carousel');
      $(owl).owlCarousel({
        loop: false,
        dots: true,
        nav: false,
        margin: 30,
        mouseDrag: false,
        items: 3,
        autoWidth: true,
        autoHeight: true,
        responsive: {
          768: {
            nav: true,
            dots: false,
          },
        },
        onInitialized: () => {
          appendNav($(owl).find('.owl-nav'));
        },
        onRefreshed: () => {
          setHeight();
        },
      });
    }
  }
  App.breakpoints.onBreakpoint(
    'xl xxl',
    () => {
      owlDestroy(slider);
      const itemsArr = [...items];
      if (itemsArr.length) {
        itemsArr.forEach((item) => {
          item.style.minHeight = 'auto';
        });
      }
    },
    () => {
      initSlider(slider);
    },
  );
  return slider;
})(jQuery);

(($) => {
  const slider = document.querySelector('.js-section-features');
  function initSlider(owl) {
    if (slider && slider.children.length > 1) {
      owl.classList.add('owl-carousel');
      $(owl).owlCarousel({
        loop: false,
        dots: true,
        nav: false,
        margin: 0,
        mouseDrag: false,
        items: 1,
        autoHeight: true,
        responsive: {
          550: {
            items: 2,
          },
        },
      });
    }
  }
  App.breakpoints.onBreakpoint(
    'xxs xs sm',
    () => {
      initSlider(slider);
    },
    () => {
      owlDestroy(slider);
    },
  );
  return slider;
})(jQuery);

(($) => {
  const self = document.querySelector('.js-section-articles');
  if (!self) return false;
  const selfHead = self.querySelector('.c-section__head');
  const slider = self.querySelector('[data-slider]');
  function appendNav(nav) {
    if (!nav) return false;
    $(nav).appendTo(selfHead);
    return nav;
  }
  function initSlider(owl) {
    if (slider && slider.children.length > 1) {
      owl.classList.add('owl-carousel');
      $(owl).owlCarousel({
        loop: false,
        dots: true,
        nav: false,
        margin: 30,
        items: 1,
        autoHeight: true,
        responsive: {
          768: {
            items: 2,
            margin: 50,
            nav: true,
            dots: false,
          },
        },
        onInitialized: () => {
          appendNav($(owl).find('.owl-nav'));
        },
      });
    }
  }
  initSlider(slider);
  return slider;
})(jQuery);
