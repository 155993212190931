import sendForm from './ajax-send';
import App from '../app';

(($) => {
  const actions = {
    'cart.clear': (data) => {
      const $html = $(data.html);
      const $form = $(data.form);
      const $cart = $form.closest('.js-cart');
      $cart.fadeOut(() => {
        if ($html) {
          $cart.replaceWith($html);
        } else {
          $cart.remove();
        }
      });
      return { html: $html[0] };
    },
    'cart.item.remove': (data) => {
      const $html = $(data.html);
      const $form = $(data.form);
      const $cart = $form.closest('.js-cart');
      const $item = $form.closest('.c-table-cart__item');
      $item.fadeOut(() => {
        $item.remove();
        if (!$cart.find('.c-table-cart__item').length) {
          if (data.html) {
            $cart.replaceWith($html);
          } else {
            $cart.remove();
          }
        }
      });
      return { html: $html[0] };
    },
    'cart.item.update': (data) => {
      if (!data.html) return data;
      const $html = $(data.html);
      const $form = $(data.form);
      $form.closest('.c-table-cart__item').replaceWith($html);
      return { html: $html[0] };
    },
    'cart.add.button': (data) => {
      if (!data.html) return data;
      const { name } = data.form;
      const $forms = $(`form[name="${name}"]`);
      const $html = $(data.html);
      $forms.replaceWith($html);
      return { html: $html[0] };
    },
    'cart-widget.item.update': data => data,
    'cart-widget.item.remove': data => data,
    'favorites.add.button': (data) => {
      if (!data.html) return data;
      const { name } = data.form;
      const $forms = $(`form[name="${name}"]`);
      const $html = $(data.html);
      $forms.replaceWith($html);
      return { html: $html[0] };
    },
    'favorites.clear': (data) => {
      if (!data.html) return data;
      const $html = $(data.html);
      const $form = $(data.form);
      const $favorites = $form.closest('.js-favorites');
      $favorites.replaceWith($html);
      return { html: $html[0] };
    },
    'favorites.item.remove': (data) => {
      const { name } = data.form;
      const $form = $(data.form);
      const $forms = $(`form[name="${name}"]`);
      const $html = $(data.html);
      const $favorites = $form.closest('.js-favorites');
      const $item = $form.closest('.c-table-favorites__item');
      if ($favorites.length) {
        $item.fadeOut(() => {
          $item.remove();
          if (!$favorites.find('.c-table-favorites__item').length) {
            window.location.reload();
          }
        });
      } else {
        $forms.replaceWith($html);
      }
      return { html: $html[0] };
    },
    'compare.item.remove': (data) => {
      const $html = $(data.html);
      const $form = $(data.form);
      const $compare = $form.closest('.js-compare');
      const $table = $form.closest('table');
      const $targetIndex = $form.data('index');
      const $cols = [];
      const $otherCols = [];
      $table.find('[data-index]').each(() => {
        if (+$(this).data('index') === $targetIndex) {
          $cols.push($(this));
        } else {
          $otherCols.push($(this));
        }
      });
      $cols.map($col => $col.remove());
      if (!$otherCols.length) {
        $compare.fadeOut(() => {
          if (data.html()) {
            $compare.replaceWith($html);
          } else {
            $compare.remove();
          }
        });
      }
      return { html: $html[0] };
    },
  };
  function submitAction(event) {
    const { action } = this.dataset;
    if (actions[action]) {
      return sendForm(event, data => actions[action](data));
    }
    return action;
  }
  $(document).on('submit', 'form[data-action]', submitAction);
})(jQuery);


(($) => {
  let changeTimeout;
  $(document).on('change', '.js-product-count', (event) => {
    const $form = $(event.target).closest('form');
    clearTimeout(changeTimeout);
    event.preventDefault();
    changeTimeout = setTimeout(() => {
      $form.submit();
    }, 500);
  });
})(jQuery);

$('.js-catalog-options').on('change', (event) => {
  const $option = $(event.target).form().find('option:selected:first');
  if ($option.length) {
    window.location.href = $option.data('url');
  }
});

$(document)
  .on(App.triggers.ajax, (event, data) => {
    if (data.cartTotals) {
      $('#js-cart-total').replaceWith($(data.cartTotals));
    }
    if (data.cartWidget) {
      const newWidget = $(data.cartWidget);
      if (newWidget.length) {
        const widget = $('#js-header-cart');
        widget.replaceWith(newWidget);
        if (!App.breakpoints.currentOf('xxs xs sm')) {
          newWidget.addClass('is-expanded');
          const timer = setTimeout(() => {
            newWidget.removeClass('is-expanded');
          }, 3000);
          newWidget.on('mouseenter', () => {
            clearTimeout(timer);
          });
        }
        $(document).trigger(App.triggers.ajax, {
          html: newWidget[0],
        });
      }
    }
    if (data.widget) {
      const newWidget = $(data.widget).find('#js-header-favorite');
      if (newWidget.length) {
        const widget = $('#js-header-favorite');
        widget.replaceWith(newWidget);
        $(document).trigger(App.triggers.ajax, {
          html: newWidget[0],
        });
      }
    }
  });
