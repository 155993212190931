import App from '../app';

/**
 * @description ховер меню за мышью
 * */
App.breakpoints.onBreakpoint(
  'xxs xs sm',
  false,
  () => {
    import(/* webpackChunkName: "menu-hover" */'../modules/menu-hover')
      .then((module) => {
        const menuHover = module.default;
        const menus = document.querySelectorAll('#js-menu-top, #js-menu-main');
        [...menus].forEach(menu => menuHover(menu));
        return menuHover;
      }).catch(App.debug);
  },
);
