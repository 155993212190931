(function ($) {
  const btn = $('.js-scroll-top');
  $(window).on('load scroll', () => {
    if ($(this).scrollTop() > 500) {
      btn.addClass('is-active');
    } else {
      btn.removeClass('is-active');
    }
  });
  btn.on('click', '', (event) => {
    $('html, body').animate({ scrollTop: 0 }, 500);
    event.preventDefault();
  });
}(jQuery));
