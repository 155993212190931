import '../jquery-plugins/input-file';
import App from '../app';

(($) => {
  function inputFiles(input) {
    $(input).inputFile({
      buttonText: '<svg class="c-icon c-icon-svg c-icon-svg-add">'
                    + '<use xlink:href="/assets/images/icons.svg#add"></use></svg>',
      emptyText: 'Файл не выбран',
      buttonClear: true,
      clearText: 'Удалить файл',
      rtl: false,
    });
  }
  function inputFilesImg(input) {
    $(input).inputFile({
      buttonText: '<svg class="c-icon c-icon-svg c-icon-svg-add">'
                  + '<use xlink:href="/assets/images/icons.svg#add"></use></svg> Прикрепить файл (dxf, dwg, pdf и т.д.)',
      emptyText: '',
      buttonClear: true,
      clearText: 'Удалить файл',
      class: 'c-input-file-image',
      rtl: false,
      onChange: (data) => {
        if (!data.files.length) {
          data.input
            .closest('.c-input-file')
            .find('.c-input-file__value')
            // .css('background-image', '')
            .attr('title', '')
            .attr('title', '');
        }
      },
      onReaderLoad: (data) => {
        const fileName = data.files[0].name;
        const btnVal = data.input.closest('.c-input-file').find('.c-input-file__value');
        const ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        if (ext === 'gif' || ext === 'png' || ext === 'jpeg' || ext === 'jpg') {
          const imgSrc = data.src[0];
          btnVal
            // .removeClass('no-image')
            .attr('title', fileName)
            .attr('title', fileName)
            // .css('background-image', `url('${imgSrc}')`);
        } else {
          btnVal
            .addClass('no-image')

            // .css('background-image', '');
        }
      },
    });
  }
  /**
   * @function formFilesImages - показываем\скрываем дополнительные input file
   * */
  function formFilesImages() {
    const self = $(this);
    const list = self.find('ul');
    const inputs = list.find('input[type="file"]');
    const btnClear = self.find('[data-reset]');
    function orderFileInputs() {
      if (list.find('.is-empty').length > 0) {
        list.find('.is-empty').hide();
        list.find('.is-empty').first().prependTo(list).show().addClass('--gray');
      }
      if (list.find('.has-file').length) {
        btnClear.show();
      } else {
        btnClear.hide();
      }
    }
    btnClear.on('click', (event) => {
      event.preventDefault();
      inputs.each(function () {
        $(this).val('').trigger('change');
      });
    });
    list.find('li').hide().first().show();
    btnClear.hide();
    inputs.each(function () {
      const input = $(this);
      const inputParent = input.closest('li');
      const firstInputParent = list.find('li')[0];
      if ($.trim(input.val()).length > 0) {
        inputParent.addClass('has-file');
        inputParent.removeClass('is-empty');
      } else {
        inputParent.addClass('is-empty');
        inputParent.removeClass('has-file');
      }
      input.on('change', () => {
        if ($.trim(input.val()).length > 0) {
          inputParent.addClass('has-file');
          inputParent.removeClass('is-empty');
        } else {
          inputParent.addClass('is-empty');
          inputParent.removeClass('has-file');
          firstInputParent.removeClass('--gray');
        }
        orderFileInputs();
      });
    });
  }
  function initFiles(html) {
    if (!html) return false;
    const $context = $(html);
    inputFiles($context.find('.js-input-file'));
    inputFilesImg($context.find('.js-input-file-img'));
    formFilesImages($context.find('.js-form-files-img').each(formFilesImages));
    return $context;
  }
  initFiles($('body'));
  App.stream.add(initFiles);
})(jQuery);
