import breakpoints from './components/breakpoints';

const App = (function () {
  const triggers = {
    ajax: 'app.ajax',
    form: {
      success: 'app.form.success',
      error: 'app.form.error',
    },
  };
  const preloader = (() => {
    let el = document.getElementById('js-loader');
    if (el) return el;
    el = document.createElement('div');
    el.className = 'app-loader';
    el.id = 'js-loader';
    return el;
  })();
  const overlay = (() => {
    let el = document.getElementById('js-overlay');
    if (el) return el;
    el = document.createElement('div');
    el.className = 'app-overlay';
    el.id = 'js-overlay';
    return el;
  })();
  const userAgent = (function () {
    const agent = navigator.userAgent.toLowerCase();
    const isIE = agent.match('trident') || agent.match('msie');
    const isMobile = (() => {
      const regex = '/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i';
      if (agent.match(regex)) {
        return agent.match(regex)[0];
      }
      return false;
    })();
    return {
      agent,
      isMobile,
      isIE,
    };
  }());
  const debug = (error) => {
    console.log(error);
  };
  return {
    debug,
    triggers,
    lang: document.documentElement.getAttribute('lang'),
    isRtl: document.documentElement.getAttribute('dir') === 'rtl',
    userAgent,
    breakpoints,
    preloader: {
      add: () => {
        document.body.appendChild(preloader);
        return preloader;
      },
      remove: () => {
        preloader.parentNode.removeChild(preloader);
      },
    },
    overlay: {
      add: () => {
        document.body.appendChild(overlay);
        return overlay;
      },
      remove: () => {
        overlay.parentNode.removeChild(overlay);
      },
    },
    redirect(url) {
      document.location.href = url;
    },
    stream: (function () {
      const streamArr = [];
      return {
        list: streamArr,
        /**
         * @function { add } добавляет функию в стрим
         * @param { function } fn - именованная функиця
         * @return { number|Error } индекс добавленной функции
         * */
        add(fn) {
          if (typeof fn !== 'function') return new Error('its not a function');
          streamArr.push(fn);
          return streamArr.length - 1;
        },
        /**
         * @function { remove } удаляет функию из стрима
         * @param { int } index - индекс функци в стриме
         * */
        remove(index) {
          if (streamArr[index]) {
            streamArr.splice(index, 1);
            return streamArr;
          }
          return false;
        },
        /**
         * @function { run } запускает функции стрима
         * @param { object } data - данные передаваемые в функции стрима
         * */
        run: data => streamArr.map(fn => ({ fn, result: fn(data) })),
      };
    }()),
    onAjaxFail(jqXHR) {
      if (this.notify && this.translate) {
        const translation = `error.title.${jqXHR.status}`;
        const translated = this.translate(translation);
        return this.notify(translated !== translation ? translated : [jqXHR.status, jqXHR.statusText].join(' '), 'error');
      }
      return jqXHR;
    },
  };
}());

$(document).on(App.triggers.ajax, (event, args) => {
  if (!args || !args.html) return false;
  const { html } = args;
  return App.stream.run(html);
});

export default App;
