/**
 * @param { integer|string } mailRuID - номер счетчика mail
 * */

const goals = (function () {
  const config = {
    mailru: {
      id: null,
    },
  };
  const metrika = (() => {
    function getCounters() {
      if (!window.Ya || !window.Ya._metrika || !window.Ya._metrika.getCounters) return false;
      const counters = window.Ya._metrika.getCounters();
      if (!counters.length) return false;
      return counters;
    }
    function send(action, category) {
      if (!action) return new Error('No action');
      const counters = getCounters();
      if (!counters) return false;
      const eventCategory = category || 'general';
      if (window.ym) {
        counters.forEach((counter) => {
          window.ym(counter.id, 'reachGoal', action, { category: eventCategory });
        });
        return true;
      }
      counters.forEach((counter) => {
        window[`yaCounter${counter.id}`].reachGoal(action, { category: eventCategory });
      });
      return true;
    }
    function getStatus() {
      return this.getCounters();
    }
    return {
      send,
      getStatus,
      getCounters() {
        const counters = getCounters();
        if (counters) {
          return counters.map(counter => counter.id);
        }
        return false;
      },
    };
  })();
  const analitics = (() => {
    function getCounters() {
      if (!window.ga || !window.ga.getAll) return false;
      const counters = window.ga.getAll();
      if (!counters.length) return false;
      return counters.map(counter => counter.get('trackingId'));
    }
    function send(action, category) {
      if (!window.ga) return false;
      if (!action) return new Error('No action');
      const eventCategory = category || 'general';
      if (window.gtag) {
        window.gtag('event', action, { event_category: eventCategory });
        return true;
      }
      window.ga('send', 'event', eventCategory, action);
      return true;
    }
    function getStatus() {
      return this.getCounters();
    }
    return { getCounters, send, getStatus };
  })();
  const pixel = (() => {
    function getCounters() {
      if (!window.fbq || !window.fbq.getState) return false;
      const counters = window.fbq.getState().pixels;
      if (!counters.length) return false;
      return counters.map(counter => counter.id);
    }
    function send(action, category) {
      if (!window.fbq) return false;
      if (!action) return new Error('No action');
      const eventCategory = category || 'general';
      window.fbq('trackCustom', action, { category: eventCategory });
      return true;
    }
    function getStatus() {
      return this.getCounters();
    }
    return { getCounters, send, getStatus };
  })();
  const mailru = (() => {
    function send(action) {
      if (!window._tmr) return false;
      if (!config.mailru.id) return new Error(typeof config.mailru.id);
      if (!action) return new Error('No action');
      window._tmr.push({
        id: config.mailru.id.toString(),
        type: 'reachGoal',
        goal: action,
      });
      return true;
    }
    function getStatus() {
      if (!window._tmr) return false;
      if (!config.mailru.id) return new Error(typeof config.mailru.id);
      return config.mailru.id;
    }
    return { send, getStatus };
  })();
  const send = (action, category) => {
    if (!action) return new Error('No action');
    const eventCategory = category || 'general';
    console.log("Goal send. Action: " + action + ", category: " + category);
    return {
      metrika: metrika.send(action, eventCategory),
      analitycs: analitics.send(action, eventCategory),
      facebook: pixel.send(action, eventCategory),
      mailRu: mailru.send(action),
    };
  };
  const getStatus = () => ({
    metrika: metrika.getStatus(),
    analitics: analitics.getStatus(),
    pixel: pixel.getStatus(),
    mailru: mailru.getStatus(),
  });
  return {
    send,
    config,
    getStatus,
    services: {
      metrika,
      analitics,
      pixel,
      mailru,
    },
  };
}());

export default goals;
