import App from '../app';
import { loadScript } from '../components/load-resource';

const captchaInit = (html) => {
  if (!html) return false;
  const context = html.jquery ? html[0] : html;
  try {
    const nodes = context.querySelectorAll('.js-recaptcha');
    if (!nodes) return false;
    const compactSizeBreakpoint = 425;
    const captchaSize = window.innerWidth < compactSizeBreakpoint? 'compact' : 'normal';
    const apiUrl = 'https://www.google.com/recaptcha/api.js?';
    loadScript(apiUrl).then(() => {
      const { grecaptcha } = window;
      grecaptcha.ready(() => {
        [...nodes].forEach((node) => {
          const key = node.dataset.sitekey;
          const theme = node.dataset.theme || 'light';
          if (key) grecaptcha.render(node, {
            sitekey: key,
            theme: theme,
            size: captchaSize
          });
        });
        return grecaptcha;
      });
    }).catch(error => error);
  } catch (e) {
    console.warn(e);
    return e;
  }
  return html;
};
captchaInit(document);
App.stream.add(captchaInit);

