import Popup from './classes/class-popup';
import App from './app';

$(document).on('click contextmenu', '.js-ajax-popup', (event) => {
  const btn = event.currentTarget;
  const url = btn.dataset.popup || btn.dataset.href || btn.href || '';
  if (btn.dataset.clicked || !url || url.indexOf('#') === 0) {
    return;
  }
  event.preventDefault();
  btn.dataset.clicked = 'true';
  btn.disabled = true;
  btn.classList.add('is-disabled', 'is-loading');
  $.ajax(url).done((html) => {
    const popup = new Popup(html, {
      classname: btn.dataset.popupClass ? btn.dataset.popupClass : '',
      transition: 500,
    });
    popup.open((opened) => {
      $(document).trigger(App.triggers.ajax, {
        html: opened,
      });
    });
  })
    .always(() => {
      btn.removeAttribute('data-clicked');
      btn.disabled = false;
      btn.classList.remove('is-disabled', 'is-loading');
    })
    .fail(App.onAjaxFail);
});
