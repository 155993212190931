import App from '../app';

(($) => {
  function initForms(html) {
    if (!html) return false;
    const $context = $(html);
    const $textareas = $context.find('.js-textarea');
    const $phones = $context.find('.js-input-phone');
    const $counters = $context.find('.js-input-counter');
    if ($counters.length) {
      import(/* webpackChunkName: "initMask" */'../jquery-plugins/input-counter')
        .then((module) => {
          $counters.each(function () {
            $(this).inputCounter({
              min: 1,
              step: 1,
              textMinus: '-',
              textPlus: '+',
            });
          });
        })
        .catch(App.debug);
    }
    if ($phones.length) {
      import(/* webpackChunkName: "initMask" */'../jquery-plugins/maskedinput')
        .then((module) => {
          const initMask = module.default;
          $phones.each(function () {
            initMask($(this), '+7(999) 999-99-99');
          });
          return initMask;
        })
        .catch(App.debug);
    }
    if ($textareas.length) {
      import(/* webpackChunkName: "textarea" */'../jquery-plugins/textarea')
        .then((module) => {
          const init = module.default;
          $textareas.each(function () {
            init($(this));
          });
          return init;
        })
        .catch(App.debug);
    }
    return $context;
  }
  initForms($('body'));
  App.stream.add(initForms);
})(jQuery);
