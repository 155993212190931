import App from '../app';

(($) => {
  const self = $('.js-header-fixed');
  const selfTop = self.offset().top;
  const scrollHandler = () => {
    if ($(window).scrollTop() > selfTop) {
      self.parent().css('height', `${self.height()}px`);
      self.addClass('is-active');
    } else {
      self.removeClass('is-active');
      self.parent().css('height', '');
    }
  };

  App.breakpoints.onBreakpoint(
    'xxs xs sm',
    () => {
      $(window).off('scroll', scrollHandler);
      self.removeClass('is-active');
      self.parent().css('height', '');
    },
    () => {
      scrollHandler();
      $(window).on('scroll', scrollHandler);
    },
  );
})(jQuery);
