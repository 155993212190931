import App from '../app';

(() => {
  /**
   * @description жирные меню
   * */
  let menuFats = null;
  const menuArr = [
    [document.querySelector('#js-menu-top'), 'menu-fat-top'],
    [document.querySelector('#js-menu-main'), 'menu-fat-main'],
  ];
  App.breakpoints.onBreakpoint(
    'lg xl xxl',
    (event) => {
      if (!menuFats) {
        import(/* webpackChunkName: "menu-fat" */'../classes/class-menu-fat')
          .then((module) => {
            const MenuFat = module.default;
            menuFats = menuArr.map((item) => {
              let menu = item[0];
              if (!menu) return false;
              menu = new MenuFat(menu, { class: item[1] || '' });
              return menu;
            });
            return MenuFat;
          })
          .catch(App.debug);
      }
    }, false,
  );
  /**
   * обновляем данные о пунктах на каждом изменении брейкпоинта
   * */
  App.breakpoints.onChange(() => {
    menuArr.forEach((item) => {
      const menu = item[0];
      menu.parentNode.style.maxWidth = '';
      if (menu) {
        menu.parentNode.style.maxWidth = `${menu.parentNode.offsetWidth}px`;
      }
    });
    if (menuFats) {
      menuFats.forEach((menu) => {
        if (menu.refresh) menu.refresh();
      });
    }
  });
})();
