import App from './app';
import LazyImg from './classes/class-lazy-image';

const selectors = {
  'js-observe': (node) => {
    node.classList.add('is-visible');
    return node;
  },
  // 'js-lazy-img': (node) => {
  //   const { src, alt, title } = node.dataset;
  //   const altAttr = alt || node.alt || '';
  //   const titleAttr = title || node.title || '';
  //   return new LazyImg(node, src, (loadedImg) => {
  //     const img = loadedImg;
  //     const classes = `${node.getAttribute('class')} a-opacity is-loaded`;
  //     img.style.cssText = node.style.cssText;
  //     img.setAttribute('class', classes);
  //     img.alt = altAttr || titleAttr || '';
  //     img.title = titleAttr || altAttr || '';
  //   }, () => {
  //     node.classList.add('is-error');
  //   });
  // },
  'js-lazy-iframe': (node) => {
    const { src } = node.dataset;
    if (!src) return false;
    const iframe = document.createElement('iframe');
    const classes = `${node.getAttribute('class')} a-opacity`;
    iframe.src = src;
    iframe.style.cssText = node.style.cssText;
    iframe.setAttribute('class', classes);
    if (node.parentNode) {
      node.parentNode.replaceChild(iframe, node);
    }
    return iframe;
  },
};

const lazyObserver = (() => {
  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target);
        const entryClass = entry.target.classList;
        Object.keys(selectors).some((selector) => {
          if (entryClass.contains(selector)) {
            entry.target.classList.remove(selector);
            return selectors[selector](entry.target);
          }
          return false;
        });
      }
      return entry.target;
    });
  };
  return new IntersectionObserver(observerCallback, {
    root: null,
    rootMargin: '0% 0px',
    threshold: 0.1,
  });
})();

const lazyInit = (context) => {
  if (context) {
    const selector = Object.keys(selectors).map(i => `.${i}`).join(',');
    try {
      const nodes = context.querySelectorAll(selector);
      if (nodes && nodes.length) {
        nodes.forEach(node => lazyObserver.observe(node));
      }
    } catch (error) {
      return App.debug(error);
    }
  }
  return context;
};

export { lazyInit as default };
export { lazyObserver };
