import App from './app';

(() => {
  const nodes = document.querySelectorAll('.js-gallery');
  if (nodes && nodes.length) {
    import(/* webpackChunkName: "photoSwipe" */'./jquery-plugins/photoswipe')
      .then((module) => {
        const photoSwipe = module.default;
        photoSwipe.init('.js-gallery');
        return photoSwipe;
      })
      .catch(App.debug);
  }
})();
