/**
 * @description обработчик ошибки формы
 * */
export default function formError(data) {
  if (!data || !data.html || !data.form) {
    return false;
  }
  const $html = $(data.html);
  const $form = $(data.form);
  $html.hide(0);
  $form.replaceWith($html);
  $html.fadeIn(() => {
    $html.find('.c-form__field .c-form-errors').each(function () {
      const $field = $(this).closest('.c-form__field');
      if ($(this).children().length) {
        $field.addClass('has-error');
        $field.find('input, textarea, select').addClass('is-error');
      }
    });
  });
  return { html: $html[0] };
}
