/**
 * @description варианты обработчиков успеха формы
 * */
const replacer = (data) => {
  if (!data || !data.form) {
    return false;
  }
  data.form.reset();
  if (!data.html) {
    return false;
  }
  const { html, form } = data;
  const parent = form.closest('.c-form');
  const success = document.createElement('div');
  success.style.display = 'none';
  success.innerHTML = html;
  if (parent) parent.classList.add('is-success');
  form.parentNode.replaceChild(success, form);
  success.style.display = '';
  return { html: success };
};

const closeable = (data) => {
  if (!data || !data.form) {
    return false;
  }
  data.form.reset();
  data.form.closest('.c-form').classList.add('is-success');
  if (!data.html) {
    return false;
  }
  const $html = $(data.html);
  const $form = $(data.form);
  const actions = {
    'success.close': (event) => {
      event.preventDefault();
      $form.find('.c-form-errors').remove();
      $form.find('.has-error').removeClass('has-error');
      $form.find('.is-error').removeClass('is-error');
      $html.fadeOut(() => {
        $html.remove();
        $form.show(0);
        $form.closest('.c-form').removeClass('is-success');
        $form.removeClass('is-success');
      });
    },
  };
  $html.on('click', '[data-action]', (event) => {
    const { action } = event.currentTarget.dataset;
    if (actions[action]) actions[action](event);
  });
  $form.hide(0);
  $html.hide(0).insertAfter($form);
  $html.show(0);
  return { html: $html[0] };
};

export { replacer, closeable };
